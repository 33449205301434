import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import { resetChatikActivatorShift, shiftChatikActivator } from 'src/models/chatikFloatActivator';

import useFloatChatikActivatorVisible from 'src/components/FloatChatikActivator/useFloatChatikActivatorVisible';

const defaultStickyBottomShift = 80;

const useChatikFloatActivatorShift = <T extends HTMLElement>() => {
    const dispatch = useDispatch();
    const stickyTrackingRef = useRef<T>(null);
    const [isStickyContainerDisplayed, setIsStickyContainerDisplayed] = useState(false);
    const shouldCalculateShift = useSelector((state) => !state.chatikFloatActivator.fixed);

    const isFloatActivatorVisible = useFloatChatikActivatorVisible();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (stickyTrackingRef.current !== null) {
            setIsStickyContainerDisplayed(true);
        } else {
            setIsStickyContainerDisplayed(false);
        }
    });

    const shiftChatik = useCallback(
        (shift: number = defaultStickyBottomShift) => {
            dispatch(shiftChatikActivator({ shift }));
        },
        [dispatch]
    );

    const resetChatikShift = useCallback(() => {
        dispatch(resetChatikActivatorShift());
    }, [dispatch]);

    useEffect(() => {
        if (!stickyTrackingRef.current || !isFloatActivatorVisible || !shouldCalculateShift) {
            return undefined;
        }

        const observer = new IntersectionObserver(
            ([e]) => {
                const isBottomSticky = e.intersectionRatio < 1 && e.intersectionRect.top > 0;

                if (isBottomSticky) {
                    shiftChatik(e.target.clientHeight);
                } else {
                    resetChatikShift();
                }
            },
            {
                rootMargin: '0px 0px -1px 0px',
                threshold: [1],
            }
        );
        observer.observe(stickyTrackingRef.current);
        return () => {
            observer.disconnect();
        };
    }, [shouldCalculateShift, isFloatActivatorVisible, isStickyContainerDisplayed, resetChatikShift, shiftChatik]);

    useEffect(() => {
        if (!isStickyContainerDisplayed || !shouldCalculateShift) {
            return undefined;
        }

        return resetChatikShift;
    }, [resetChatikShift, isStickyContainerDisplayed, shouldCalculateShift]);

    return { stickyTrackingRef, shiftChatik, resetChatikShift };
};

export default useChatikFloatActivatorShift;
