import { useState, useCallback, ReactNode } from 'react';

import { CurrencyType } from 'src/models/currencies.types';
import { PriceWithFormatData } from 'src/models/price/priceBranding/priceBranding.types';

import SelectRegion, {
    ALL_RUSSIA,
    BELARUS,
    HandleOnSelectType,
} from 'src/components/EmployerConstructor/price/SelectRegion';
import SelectRegionSwitcher, {
    CustomSwitcherData,
} from 'src/components/EmployerConstructor/price/SelectRegionSwitcher';

interface UseSelectRegionArgs {
    currentRegionIds?: string[];
    defaultRegionsId: string[];
    prices: PriceWithFormatData[];
    slimKind?: boolean;
    prefixText?: string;
    hasCustomSwitcher?: boolean;
    hasService?: boolean;
}

export interface SelectRegionParams {
    cost: number;
    hasPrice: boolean;
    currency: false | CurrencyType;
    period: false | string;
    selectedRegions: PriceWithFormatData[];
    selectRegionElement: false | ReactNode;
    customSwitcherData: CustomSwitcherData;
}

type UseSelectRegionType = (params: UseSelectRegionArgs) => SelectRegionParams;

const useSelectRegion: UseSelectRegionType = ({
    currentRegionIds,
    defaultRegionsId,
    prices,
    slimKind,
    prefixText,
    hasCustomSwitcher = false,
    hasService,
}) => {
    const currentRegionPrice = prices.filter((price) => currentRegionIds?.includes(price.priceRegionId));

    let defaultRegionPrice = currentRegionPrice.length
        ? currentRegionPrice
        : prices?.filter((price) => defaultRegionsId.includes(price.priceRegionId));
    if (!defaultRegionPrice?.length) {
        defaultRegionPrice = prices?.filter((price) => price.priceRegionId === ALL_RUSSIA);
    }
    const regionSwitchLinkDisabled = prices.length === 1 && prices[0].priceRegionId === BELARUS;
    const hasPrice = !!defaultRegionPrice?.length;
    const [selectedRegions, setSelectedRegions] = useState(defaultRegionPrice);
    const [showRegionSelect, setShowRegionSelect] = useState(false);
    const [cost, setCost] = useState(defaultRegionPrice?.reduce((sum, region) => sum + region.price, 0));
    const onClickRegionSwitcher = useCallback(() => setShowRegionSelect(true), []);
    const customSwitcherData: CustomSwitcherData = { slimKind, prefixText, selectedRegions, onClickRegionSwitcher };
    const handleSelect: HandleOnSelectType = useCallback((selectedRegions, priceSum) => {
        setSelectedRegions(selectedRegions);
        setCost(priceSum);
    }, []);
    const selectRegionElement = hasPrice && (
        <>
            {!hasCustomSwitcher && (
                <SelectRegionSwitcher
                    slimKind={slimKind}
                    prefixText={prefixText}
                    selectedRegions={selectedRegions}
                    onClickRegionSwitcher={onClickRegionSwitcher}
                    regionSwitchLinkDisabled={regionSwitchLinkDisabled}
                />
            )}
            <SelectRegion
                visible={showRegionSelect}
                onClose={() => setShowRegionSelect(false)}
                regionPrices={prices}
                hasService={hasService}
                defaultRegions={selectedRegions.map(({ priceRegionId }) => priceRegionId)}
                onSelect={handleSelect}
            />
        </>
    );

    return {
        cost,
        hasPrice,
        currency: hasPrice && selectedRegions[0].currency,
        period: hasPrice && selectedRegions[0].period,
        selectedRegions,
        selectRegionElement,
        customSwitcherData,
    };
};

export default useSelectRegion;
