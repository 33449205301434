import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import conctructorSaveFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_save_form_submit';
import { useMultipleRefs } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import saveDescriptionRequest from 'src/api/employer/saveDescription';
import getErrorType from 'src/components/EmployerConstructor/getErrorType';
import useSelectRegion from 'src/components/EmployerConstructor/price/useSelectRegion';
import useChatikFloatActivatorShift from 'src/components/FloatChatikActivator/useChatikFloatActivatorShift';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import useScroll from 'src/hooks/useScroll';
import { useSelector } from 'src/hooks/useSelector';
import {
    setEditMode,
    Status,
    setSaveDescriptionErrorType,
    isSaveDescriptionError,
    SaveDescriptionErrorType,
} from 'src/models/employerConstructor';
import saveConstructorTemplate from 'src/pages/EmployerConstructor/components/saveConstructorTemplate';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ControlContentEditMode from 'src/pages/EmployerConstructor/components/Controls/ControlContentEditMode';
import ControlContentPreviewMode from 'src/pages/EmployerConstructor/components/Controls/ControlContentPreviewMode';

import styles from './controls.less';

const getSaveDescriptionError = (error: unknown) => {
    const errorType = getErrorType(error);

    if (!isSaveDescriptionError(errorType)) {
        return SaveDescriptionErrorType.Unknown;
    }

    return errorType;
};

const TrlKeys = {
    regionPrefix: 'employer.constructor.controls.regionPrefix',
};

interface ControlsProps {
    expirationTime?: string;
}

const Controls: TranslatedComponent<ControlsProps> = ({ trls }) => {
    const dispatch = useDispatch();
    const editMode = useSelector((state) => state.employerConstructor.editMode);
    const tabPrices = useSelector((state) => state.employerConstructor.tabPrices);
    const hasUnsavedChanges = useSelector((state) => state.employerConstructor.hasUnsavedChanges);
    const infoHasUnsavedChanges = useSelector((state) => state.employerInfo.hasUnsavedChanges);
    const saveStatus = useSelector((state) => state.employerConstructor.saveStatus);
    const description = useSelector((state) => state.employerInfo.description);
    const saveDescriptionStatus = useSelector((state) => state.employerInfo.saveDescriptionStatus);
    const employerId = useSelector((state) => state.employerInfo.id);
    const defaultRegionsId = useSelector((state) => state.employerConstructor.defaultPriceRegions);
    const hasService = useSelector((state) => state.employerConstructor.hasServiceExpiration);
    const [sticked, setSticked] = useState(false);
    const derivedEditMode = useRef(editMode);
    const controlsRef = useRef<HTMLDivElement>(null);
    const breakpoint = useBreakpoint(Breakpoint.L);
    const isZP = useIsZarplataPlatform();
    const toggleEditMode = useCallback(() => {
        derivedEditMode.current = !editMode;
        dispatch(setEditMode(!editMode));
    }, [dispatch, editMode]);
    const { addNotification } = useNotification();

    const saveConstructor = useCallback(() => {
        dispatch(saveDescriptionRequest(description))
            .then(() => {
                void dispatch(saveConstructorTemplate(addNotification));
                conctructorSaveFormSubmit();
            })
            .catch((error) => {
                const descriptionError = getSaveDescriptionError(error);
                dispatch(setSaveDescriptionErrorType(descriptionError));
                conctructorSaveFormSubmit({ errors: descriptionError });
            });
    }, [addNotification, description, dispatch]);
    useEffect(() => {
        if (!derivedEditMode.current) {
            return;
        }
        dispatch(setEditMode(breakpoint !== Breakpoint.XS));
    }, [breakpoint, dispatch]);
    useScroll(() => {
        if (!controlsRef.current) {
            return;
        }
        const elementOffsetTop = controlsRef.current.offsetHeight + controlsRef.current.offsetTop;
        const elementIsSticked = window.scrollY + window.innerHeight > elementOffsetTop;
        setSticked(elementIsSticked);
    });

    // tempexp_PORTFOLIO-38480_next_line
    const { stickyTrackingRef } = useChatikFloatActivatorShift();
    const saveInProgress = saveDescriptionStatus === Status.Fetching || saveStatus === Status.Fetching;
    const hasSave = hasUnsavedChanges || infoHasUnsavedChanges;

    const selectRegionProps = useSelectRegion({
        defaultRegionsId,
        prices: tabPrices,
        slimKind: true,
        hasService,
        prefixText: `${trls[TrlKeys.regionPrefix]}:${NON_BREAKING_SPACE}`,
    });

    return (
        <div
            className={classnames(styles.employerConstructorControls, {
                [styles.employerConstructorControlsSticked]: sticked && editMode,
                [styles.employerConstructorControlsHide]: isZP && !editMode,
            })}
            ref={useMultipleRefs(controlsRef, stickyTrackingRef)}
        >
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    {editMode ? (
                        <ControlContentEditMode
                            saveConstructor={saveConstructor}
                            hasSave={hasSave}
                            employerId={String(employerId)}
                            toggleEditMode={toggleEditMode}
                            saveInProgress={saveInProgress}
                            hasService={hasService}
                            selectRegionProps={selectRegionProps}
                        />
                    ) : (
                        <ControlContentPreviewMode toggleEditMode={toggleEditMode} />
                    )}
                </Column>
            </ColumnsWrapper>
        </div>
    );
};

export default translation(Controls);
