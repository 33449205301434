import classNames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerTabButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_tab_button_click';
import employerTabElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_tab_element_shown';
import { Tabs, Tab, Button, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';

import scrollToElement from 'Utils/ScrollToElement';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import VacanciesTabInfoTip from 'src/components/Employer/PageTabs/VacanciesTabInfoTip';

const TrlKeys = {
    [EmployerPageTab.Description]: 'employer.view.tab.description',
    [EmployerPageTab.Vacancies]: 'employer.view.tab.vacancies',
    activeVacancies: 'employer.view.tab.vacancies.active',
};

const tabTypeMap = {
    [EmployerPageTab.Description]: 'employer_about_company_tab',
    [EmployerPageTab.Vacancies]: 'employer_vacancy_tab',
} as const;

const EmployerPageTabs: TranslatedComponent = ({ trls }) => {
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const vacancyCount = useSelector(
        (state) => state.vacanciesGroupsByRegion?.count ?? state.activeEmployerVacancyCount
    );
    const formattedVacancyCount = vacancyCount
        ? numberFormatter.format(String(vacancyCount), {
              decimalLength: 0,
              groupSeparator: NON_BREAKING_SPACE,
          })
        : '';
    const employerId = useSelector((state) => state.employerInfo.id);
    const { currentTab, setCurrentTab, isMobileView } = usePageTabsContext();

    if (!employerId) {
        return null;
    }

    const analyticsParams = { vacancyCount, employerId };

    if (isMobileView && !isMagritteEmployerPageHeaderExp) {
        // На XS активатор выводим только на главной
        if (currentTab !== EmployerPageTab.Description) {
            return null;
        }
        // Вместо вкладок кнопка "Активные вакансии"
        return (
            <>
                <VSpacing default={28} />
                <ElementShownAnchor fn={employerTabElementShown} tabType="employer_vacancy_tab" {...analyticsParams}>
                    <Button
                        onClick={() => {
                            setCurrentTab(EmployerPageTab.Vacancies, true);
                            employerTabButtonClick({ tabType: 'employer_vacancy_tab', ...analyticsParams });
                            window.scrollTo(0, 0);
                        }}
                        mode="primary"
                        style="accent"
                        postfix={formattedVacancyCount}
                        stretched
                        data-qa="employer-page-tabs-xs-go-active-vacancies"
                    >
                        <div style={{ textAlign: 'left' }}>{trls[TrlKeys.activeVacancies]}</div>
                    </Button>
                </ElementShownAnchor>
            </>
        );
    }

    return (
        <>
            {!isMagritteEmployerPageHeaderExp && <VSpacing default={24} />}
            <Tabs activeItemId={currentTab} onChange={setCurrentTab}>
                {Object.values(EmployerPageTab).map((tab) => {
                    const textTab = trls[TrlKeys[tab]];
                    const tabType = tabTypeMap[tab];

                    return (
                        <Tab
                            onClick={() => {
                                if (hasChameleon) {
                                    const vacanciesBlock = document.querySelector('.HH-Employer-Vacancies-Block');
                                    if (vacanciesBlock !== null) {
                                        scrollToElement(vacanciesBlock, { topOffset: 0 });
                                    }
                                    return;
                                }
                                setCurrentTab(tab);
                                employerTabButtonClick({ tabType, ...analyticsParams });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            id={tab}
                            key={tab}
                            postfix={tab === EmployerPageTab.Vacancies ? formattedVacancyCount : undefined}
                            data-qa={classNames(`employer-page-tabs-desktop-go-${tab}`, {
                                'employer-page-tabs-desktop-active': tab === currentTab,
                            })}
                        >
                            <ElementShownAnchor fn={employerTabElementShown} tabType={tabType} {...analyticsParams}>
                                {tab === EmployerPageTab.Vacancies ? (
                                    <VacanciesTabInfoTip currentTab={currentTab}>{textTab}</VacanciesTabInfoTip>
                                ) : (
                                    textTab
                                )}
                            </ElementShownAnchor>
                        </Tab>
                    );
                })}
            </Tabs>
        </>
    );
};

export default translation(EmployerPageTabs);
