import { useRef } from 'react';
import classNames from 'classnames';

import conctructorChangeRegionButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_change_region_button_click';
import { InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { PriceWithFormatData } from 'src/models/price/priceBranding/priceBranding.types';
import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './select-region-switcher.less';

const TrlKeys = {
    changeLink: 'employer.price.branding.periodItem.change',
    regionTip: 'employer.price.branding.regionTip',
    regionEtc: 'employer.price.branding.regionEtc',
};

export interface CustomSwitcherData {
    regionSwitchLinkDisabled?: boolean;
    slimKind?: boolean;
    prefixText?: string;
    selectedRegions: PriceWithFormatData[];
    onClickRegionSwitcher: () => void;
}

const SelectRegionSwitcher: TranslatedComponent<CustomSwitcherData> = ({
    prefixText,
    slimKind,
    trls,
    selectedRegions,
    onClickRegionSwitcher,
    regionSwitchLinkDisabled = false,
}) => {
    const activatorRef = useRef(null);
    return (
        <div className={styles.priceBrandingRegionSwitcher}>
            {!regionSwitchLinkDisabled && (
                <span
                    className={styles.priceBrandingRegionSwitcherLink}
                    data-qa="branding-region-popup-switcher"
                    onClick={() => {
                        conctructorChangeRegionButtonClick();
                        onClickRegionSwitcher();
                    }}
                >
                    <Text Element="span">{trls[TrlKeys.changeLink]}</Text>
                </span>
            )}
            <div
                data-qa="constructor-region-list"
                className={classNames(styles.priceBrandingRegionSwitcherList, {
                    [styles.priceBrandingRegionSwitcherListSlim]: slimKind,
                })}
            >
                {prefixText}
                {selectedRegions[0].priceRegionTrl}
                {selectedRegions.length > 1 &&
                    `${NARROW_NON_BREAKING_SPACE}${trls[TrlKeys.regionEtc]}${NARROW_NON_BREAKING_SPACE}${
                        selectedRegions.length - 1
                    }`}
                {NARROW_NON_BREAKING_SPACE}
                <HoverTip render={() => trls[TrlKeys.regionTip]} activatorRef={activatorRef}>
                    <span ref={activatorRef}>
                        <BlokoIconReplaceContainer>
                            <InfoCircleOutlinedSize16 initialColor="secondary" />
                        </BlokoIconReplaceContainer>
                    </span>
                </HoverTip>
            </div>
        </div>
    );
};

export default translation(SelectRegionSwitcher);
