import { useEffect, useMemo, useRef } from 'react';

import { usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import { useSelector } from 'src/hooks/useSelector';
import { ViewDurationEvent, ViewDurationProps } from 'src/models/viewDuration';
import { addTabListener, removeTabListener } from 'src/utils/viewDuration';

const useViewDurationTracking = (): void => {
    const viewDuration = useSelector((state) => state.viewDuration);
    const { currentTab } = usePageTabsContext();
    const prevConfigRef = useRef<ViewDurationProps | null>(null);

    const durationConfig = useMemo<ViewDurationProps | null>(() => {
        if (!viewDuration) {
            return null;
        }

        return {
            eventType: ViewDurationEvent.EmployerPageViewDuration,
            params: {
                ...viewDuration.params,
                currentTab,
            },
        };
    }, [currentTab, viewDuration]);

    const hasConfigChanged = useMemo(() => {
        if (!prevConfigRef.current || !durationConfig) {
            return false;
        }

        return (
            prevConfigRef.current.params.currentTab !== durationConfig.params.currentTab ||
            JSON.stringify(prevConfigRef.current.params) !== JSON.stringify(durationConfig.params)
        );
    }, [durationConfig]);

    useEffect(() => {
        if (!durationConfig) {
            return undefined;
        }

        addTabListener(durationConfig);
        prevConfigRef.current = durationConfig;

        return () => {
            removeTabListener(hasConfigChanged);
        };
    }, [durationConfig, hasConfigChanged]);
};

export default useViewDurationTracking;
