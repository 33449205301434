import { useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import conctructorBuyButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_buy_button_click';
import conctructorBuyElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_buy_element_shown';
import conctructorCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_close_button_click';
import conctructorPreviewButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_preview_button_click';
import { SPALink, usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { createCart } from 'src/api/price/cart';
import EmployerConstructorHideOnXS from 'src/components/EmployerConstructor/EmployerConstructorHideOnXS';
import { SelectRegionParams } from 'src/components/EmployerConstructor/price/useSelectRegion';
import FormatMoney from 'src/components/FormatMoney';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import fetcher from 'src/utils/fetcher';

import styles from './controls.less';

enum ContentType {
    Zarplata = 'zarplata',
    Default = 'default',
}

const styleByContentTypeMap = {
    [ContentType.Zarplata]: styles.employerConstructorControlsContentEditZarplata,
    [ContentType.Default]: styles.employerConstructorControlsContentEditDefault,
};

const TrlKeys = {
    goToPreview: 'employer.constructor.controls.goToPreview',
    goToConstructor: 'employer.constructor.controls.goToConstructor',
    cancel: 'employer.constructor.controls.cancel',
    save: 'employer.constructor.controls.save',
    saved: 'employer.constructor.controls.saved',
    savedTip: 'employer.constructor.controls.saved.tip',
    buy: 'employer.constructor.controls.buy',
    buyFor: 'employer.constructor.controls.buyFor',
    prolongation: 'employer.constructor.controls.prolongation',
    cautionHint: 'price.branding.regionselect.cautionHint',
    regionTitle: 'employer.constructor.controls.regionTitle',
    editModeDisabled: 'employer.constructor.controls.editModeDisabled',
    preview: 'employer.constructor.controls.preview',
    activate: 'employer.constructor.controls.activate',
};

interface ControlContentEditModeProps {
    saveConstructor?: () => void;
    hasSave?: boolean;
    toggleEditMode?: () => void;
    saveInProgress?: boolean;
    employerId: string;
    hasService?: boolean;
    selectRegionProps: SelectRegionParams;
}

const ControlContentEditMode: TranslatedComponent<ControlContentEditModeProps> = ({
    saveConstructor,
    hasSave,
    employerId,
    trls,
    toggleEditMode,
    saveInProgress,
    hasService,
    selectRegionProps: { selectedRegions, currency, cost, hasPrice, selectRegionElement },
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const { addNotification } = useNotification();
    const push = usePush();
    const readyToActivateCartId = useSelector(({ employerConstructor }) => employerConstructor.readyToActivateCartId);
    const readyToActivateCartAgreementId = useSelector(
        ({ employerConstructor }) => employerConstructor.readyToActivateCartAgreementId
    );
    const isZP = useIsZarplataPlatform();
    const activatorBuyRef = useRef(null);
    const activatorSaveRef = useRef(null);

    const activateCart = useCallback(async () => {
        if (!readyToActivateCartId || !readyToActivateCartAgreementId) {
            return;
        }
        try {
            const response = await fetcher.post('/shards/employer/carts/validate', null, {
                params: {
                    cartId: readyToActivateCartId,
                    agreementId: readyToActivateCartAgreementId,
                    employerId,
                },
            });
            push(response.data.redirectUrl);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    }, [readyToActivateCartId, readyToActivateCartAgreementId, employerId, push, addNotification]);

    const activateButtonElement = useMemo(
        () => (
            <Button
                data-qa="cart-item__button-activate"
                name="employer_constructor_activate"
                onClick={activateCart}
                kind={ButtonKind.Success}
            >
                {trls[TrlKeys.activate]}
            </Button>
        ),
        [activateCart, trls]
    );

    const handleClickCreateCart = useCallback(() => {
        createCart(selectedRegions, addNotification, push).catch(console.error);
        conctructorBuyButtonClick();
    }, [addNotification, push, selectedRegions]);

    const buyButtonElement = useMemo(
        () =>
            readyToActivateCartId
                ? activateButtonElement
                : hasPrice && (
                      <ElementShownAnchor fn={conctructorBuyElementShown}>
                          <Button
                              data-qa="cart-item__button-buy"
                              name="employer_constructor_buy"
                              {...(hasService
                                  ? {
                                        appearance: ButtonAppearance.Outlined,
                                    }
                                  : {
                                        kind: ButtonKind.Success,
                                    })}
                              onClick={handleClickCreateCart}
                          >
                              {hasService ? trls[TrlKeys.prolongation] : trls[TrlKeys.buyFor]}
                              {NON_BREAKING_SPACE}
                              {currency && <FormatMoney currency={currency}>{cost}</FormatMoney>}
                          </Button>
                      </ElementShownAnchor>
                  ),
        [
            activateButtonElement,
            cost,
            currency,
            hasService,
            handleClickCreateCart,
            hasPrice,
            readyToActivateCartId,
            trls,
        ]
    );

    const saveButton = (
        <Button
            data-qa="employer-constructor-save-button"
            disabled={!hasSave}
            onClick={saveConstructor}
            kind={ButtonKind.Primary}
            loading={saveInProgress && <Loading scale={LoadingScale.Small} />}
        >
            {hasSave ? trls[TrlKeys.save] : trls[TrlKeys.saved]}
        </Button>
    );

    const contentType = isZP ? ContentType.Zarplata : ContentType.Default;

    return (
        <div
            ref={contentRef}
            className={classnames(styles.employerConstructorControlsContent, styleByContentTypeMap[contentType])}
        >
            {hasPrice && (
                <div className={styles.employerConstructorControlsBuyForm}>
                    {!readyToActivateCartId && (
                        <div className={styles.employerConstructorControlsRegion}>
                            <Text size={TextSize.Large} strong>
                                {trls[TrlKeys.regionTitle]}
                            </Text>
                            {selectRegionElement}
                        </div>
                    )}
                    <Gap left>
                        {hasService ? (
                            <HoverTip
                                host={contentRef.current}
                                render={() => trls[TrlKeys.cautionHint]}
                                activatorRef={activatorBuyRef}
                            >
                                <div ref={activatorBuyRef}>{buyButtonElement}</div>
                            </HoverTip>
                        ) : (
                            buyButtonElement
                        )}
                    </Gap>
                </div>
            )}
            <EmployerConstructorHideOnXS>
                <Button
                    data-qa="employer-constructor-cancel-button"
                    disabled={!hasSave}
                    Element={SPALink}
                    to="/employer/constructor"
                    onClick={() => conctructorCloseButtonClick()}
                >
                    {trls[TrlKeys.cancel]}
                </Button>
                <FormSpacer>
                    {hasSave ? (
                        saveButton
                    ) : (
                        <HoverTip
                            host={contentRef.current}
                            render={() => trls[TrlKeys.savedTip]}
                            activatorRef={activatorSaveRef}
                        >
                            <div ref={activatorSaveRef}>{saveButton}</div>
                        </HoverTip>
                    )}
                </FormSpacer>
                {!isZP && (
                    <FormSpacer>
                        <Button
                            data-qa="employer-constructor-preview-button"
                            disabled={false}
                            onClick={() => {
                                conctructorPreviewButtonClick();
                                toggleEditMode?.();
                            }}
                            appearance={ButtonAppearance.Outlined}
                        >
                            {trls[TrlKeys.preview]}
                        </Button>
                    </FormSpacer>
                )}
            </EmployerConstructorHideOnXS>
        </div>
    );
};

export default translation(ControlContentEditMode);
